/* eslint-disable no-console */
import { useState, useEffect } from 'react';
import vwoSdk from 'vwo-node-sdk';
import isEqual from 'lodash/isEqual';

import buildTimeSettingsFile from 'utils/vwoSettingsFile.json';
import { browserLogger } from 'utils/vwo/browserLogger';
import { logger } from 'utils/logger';

const LOCAL_STORAGE_KEY = 'vwo_settings_file';

const useVwo = () => {
  const [vwoClient, setVwoClient] = useState(null);
  const isDevelopmentMode = process.env.NEXT_PUBLIC_HI_ENV === 'development';

  useEffect(() => {
    const revalidateSettingsFile = async (currentFile) => {
      try {
        const settingsFile = await vwoSdk.getSettingsFile(
          process.env.NEXT_PUBLIC_VWO_ACCOUNT_ID,
          process.env.NEXT_PUBLIC_VWO_SDK_KEY
        );
        if (settingsFile && !isEqual(currentFile, settingsFile)) {
          window?.localStorage?.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settingsFile));
          const client = vwoSdk.launch({
            settingsFile,
            logging: browserLogger,
          });
          setVwoClient(client);
        }
      } catch (err) {
        console.warn('Error revalidating VWO settings file - browser', err);
      }
    };

    const getSettingsFile = () => {
      let settingsFile;

      const cachedFile = window?.localStorage?.getItem(LOCAL_STORAGE_KEY);
      if (cachedFile) {
        settingsFile = JSON.parse(cachedFile);
      } else {
        settingsFile = buildTimeSettingsFile;
        window?.localStorage?.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify(buildTimeSettingsFile)
        );
      }
      revalidateSettingsFile(settingsFile);

      return settingsFile;
    };

    const getVwoClient = () => {
      try {
        const settingsFile = getSettingsFile();
        const client = vwoSdk.launch({
          settingsFile,
          logging: browserLogger,
        });
        setVwoClient(client);
      } catch (err) {
        logger.error('Error launching VWO client', err);
      }
    };

    getVwoClient();
  }, [isDevelopmentMode]);

  return vwoClient;
};

export default useVwo;
