import PropTypes from 'prop-types';
import styled from 'styled-components';
import { variant, compose, space, layout, color, typography } from 'styled-system';
import css from '@styled-system/css';

import { boxProps } from 'humanity/primitives/box';

const borderWidth = '3px';

const Button = styled.button(
  css({
    display: 'inline-block',
    borderWidth,
    borderStyle: 'solid',
    borderRadius: 'xs',
    appearance: 'none',
    cursor: 'pointer',
    textAlign: 'center',
    fontFamily: 'inherit',
    fontWeight: 'button',
    lineHeight: 'button',
    letterSpacing: '0.02em',
    userSelect: 'none',
    textDecoration: 'none',
    transition:
      'background 250ms ease-in, border-color 250ms ease-in-out, transform 150ms ease, box-shadow 300ms ease',
    '&:focus': {
      outline: 0,
      boxShadow: 'buttonFocus',
    },
    '&:disabled': {
      bg: 'gray20',
      borderColor: 'transparent',
      color: 'gray70',
      cursor: 'not-allowed',
      '&:hover': {
        bg: 'gray20',
      },
    },
  }),
  variant({
    variants: {
      primary: {
        bg: 'blue100',
        borderColor: 'transparent',
        color: 'white',
        '&:hover': {
          bg: 'blueDark',
        },
      },
      secondary: {
        bg: 'teal50',
        borderColor: 'transparent',
        color: 'blue100',
        '&:hover': {
          bg: 'teal100',
        },
      },
      outline: {
        bg: 'transparent',
        borderColor: 'blue100',
        color: 'blue100',
        '&:hover': {
          borderColor: 'blueDark',
          color: 'blueDark',
        },
      },
      select: {
        bg: 'transparent',
        border: 'none',
        color: 'blue100',
        marginRight: '16px',
        '&:hover': {
          fontWeight: 'bold',
          backgroundColor: 'backgroundLightGray2',
        },
      },
      circle: {
        bg: 'transparent',
        border: 'none',
        borderWidth: '0px',
        borderRadius: 'circle',
        width: '48px',
        height: '48px',
        mx: '1px',
        '&:hover': {
          backgroundColor: 'backgroundLightGray2',
          fontWeight: 'bold',
        },
        '&:focus': {
          boxShadow: 'none',
        },
      },
    },
  }),
  variant({
    prop: 'active',
    variants: {
      active: {
        fontWeight: 'bold',
        backgroundColor: 'backgroundLightGray2',
      },
    },
  }),
  ({ theme }) =>
    variant({
      prop: 'btnSize',
      variants: {
        sm: {
          px: `calc(12px - ${borderWidth})`,
          py: `calc(9px - ${borderWidth})`,
          fontSize: 1,
        },
        md: {
          px: `calc(${theme.space[3]}px - ${borderWidth})`,
          py: `calc(12px - ${borderWidth})`,
          fontSize: 2,
        },
        lg: {
          px: `calc(${theme.space[5]}px - ${borderWidth})`,
          py: `calc(${theme.space[3]}px - ${borderWidth})`,
          fontSize: 2,
        },
      },
    }),
  compose(space, layout, color, typography)
);

Button.displayName = 'Button';

export const btnVariants = ['primary', 'secondary', 'outline', 'select', 'circle'];
export const btnSizes = ['sm', 'md', 'lg'];
export const sizeProp = PropTypes.oneOf([...btnSizes]);

Button.propTypes = {
  ...boxProps,
  variant: PropTypes.oneOf([...btnVariants]),
  btnSize: PropTypes.oneOfType([sizeProp, PropTypes.arrayOf(sizeProp)]),
};

Button.defaultProps = {
  variant: 'primary',
  btnSize: 'md',
};

export default Button;
